$bin-height: 25px;
$bin-width: 14px;
$legend-height: calc(12 * $bin-height);
$small-bin-height: 16px;
$small-bin-width: 6px;
$small-legend-height: calc(12 * $small-bin-height);

.legend-container {
  position: absolute;
  box-sizing: content-box;
  bottom: 8px;
  right: 8px;
  width: 40px;
  height: $legend-height;
  background-color: white;
  border: 1px solid black;
  padding-left: 2px;
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;

  .legend-title {
    position: absolute;
    bottom: -1px;
    left: -20px;
    white-space: nowrap;
    writing-mode: vertical-lr;
    line-height: 13px;
    background-color: white;
    border: 1px solid black;
    border-right: none;
    padding: 10px 3px;
    font-size: 13px;
    font-weight: bold;
  }

  .legend-squares-container {
    display: flex;
    flex-direction: column;

    .legend-square {
      height: $bin-height;
      display: flex;

      .legend-square-spacer {
        width: 2px;
      }
      
      .legend-square-color-block {
        width: calc($bin-width - 2px);
        opacity: 0.75;
      }
    }

    .legend-square:not(:last-child) {
      border-bottom: 1px solid black;
    }
  }

  .legend-labels-container {
    text-align: end;
    margin-top: calc($bin-height / 2 + 6px);
    width: 23px;
    font-size: 10px;

    .legend-label {
      position: relative;
      height: $bin-height;
      z-index: 2;
    }
  }
}

@media (max-width: 590px) {
  .legend-container {
    height: $small-legend-height;
    width: 28px;

    .legend-title {
      font-size: 11px;
      line-height: 8px;
      left: -15px;
      padding: 8px 3px;
    }

    .legend-labels-container {
      margin-top: calc($small-bin-height / 2 - 1.5px);
      width: 19px;
      font-size: 9px;

      .legend-label {
        height: $small-bin-height;

        &::after {
          width: calc($small-bin-width + 4px);
          top: 9px;
        }
      }
    }

    .legend-squares-container {
      .legend-square {
        height: $small-bin-height;
        width: $small-bin-width;
      }
    }
  }
}

@media (max-width: 785px), (max-height: 550px) {
  .legend-container {
    bottom: 6px;
    right: 6px;
  }
}
