$accent-color: rgb(165, 163, 163);

.threshold-selector-content-container {
  .no-thresholds {
    font-size: 14px;
    font-style: italic;
    color: rgb(120, 120, 120);
    text-align: center;
    margin-bottom: 12px;
  }

  .header-container {
    display: flex;
    border-bottom: 1px solid $accent-color;
    font-size: 12px;
    font-weight: bold;
    color: rgb(150, 150, 150);
    align-items: center;
    justify-content: space-around;
    padding-right: 8px;

    .header-value {
      width: 55px;
      text-align: center;
    }

    .header-controls-container {
      display: flex;
      gap: 6px;
    }
  }

  .add-button-container {
    border-top: 1px dashed $accent-color;
    padding: 5px 0px;
  }
}
