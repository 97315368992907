.loc-marker {
  border: none;
  cursor: pointer;
  height: 40px;
  width: 20px;
}

.mapboxgl-popup {
  padding-bottom: 30px;
}

.mapboxgl-popup-content {
  font-family: 'Raleway', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  font-size: 14px/18px;
  padding: 0 !important;
  width: 250px;

  div {
    h3 {
      background: black;
      color: #fff;
      margin: 0;
      padding: 10px 8px;
      border-radius: 3px 3px 0 0;
      font-weight: 700;
      text-align: center;
    }

    h4 {
      margin: 0;
      padding: 10px 5px;
      font-weight: 700;
      text-align: center;
    }

    h5 {
      margin: 0;
      padding: 0;
      font-weight: 400;
      text-align: center;
      font-style: italic;
      font-size: 11px;
      color: rgb(100, 100, 100);
    }
  }
}

.mapboxgl-popup-anchor-top {
  .mapboxgl-popup-content {
    margin-top: 15px;
  }
}

.mapboxgl-popup-anchor-top {
  .mapboxgl-popup-tip {
    border-bottom-color: black;
  }
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-tip {
  display: none;
}