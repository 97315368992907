$blur-amount: 4px;

.loading-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur($blur-amount);
  backdrop-filter: blur($blur-amount);

}
.loading-text-container {
  background-color: black;
  color: white;
  border-radius: 8px;
  height: 50px;
  width: 150px;
  padding-left: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .loading-text {
    font-size: 20px;
    font-weight: bold;
  }

  .loading-text:after {
    content: '...';
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis-dot 1.7s infinite 0.3s;
    animation-fill-mode: forwards;
    width: 1.25em;
  }
}

@keyframes ellipsis-dot {
  25% {
    content: '';
  }
  50% {
    content: '.';
  }
  75% {
    content: '..';
  }
  100% {
    content: '...';
  }
}
