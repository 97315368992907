$arrow-size: 19px;

.threshold-range-item-value {
  width: 55px;

  .MuiInput-underline:after {
    border-bottom-color: rgb(150, 150, 150);
  }

  input {
    padding-bottom: 0px;
  }
}

.threshold-range-item-controls {
  display: flex;
  align-items: center;

  .arrow-container {
    width: $arrow-size;
    height: $arrow-size;
    text-align: center;
    padding: 1px;
    border-radius: 50%;

    &:hover {
      background-color: rgb(220, 220, 220);
      cursor: pointer;
    }

    .arrow {
      position: relative;
      top: -1px;
    }
  }
}
