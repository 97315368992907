.text-panel {
    margin: 20px auto;

    .text-panel-md-content {
        padding: 0px 0px 0px 12px;

        p {
            margin: 4px 0px 8px;
        }    
    }

    .tabs{
        border-bottom: 1px solid rgb(200, 200, 200);
        max-width: 180px;
        &:last-child{
            border-bottom: none;
        }
    }
}