.radio-select-container {
  margin-left: 12px;

  .radio-select-title {
    font-weight: bold;
    position: relative;
    left: 20px;
  }

  .radio-btn {
    color: rgb(150, 150, 150);

    &:hover {
      background-color: rgba(120, 120, 120, 0.05);
    }

    &.Mui-checked {
      color: black;
    }
  }

  .MuiFormControlLabel-label {
    font-family: 'Raleway', 'Roboto', 'Helvetica Neue', sans-serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
  }
}
