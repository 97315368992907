.button-container {
  width: fit-content;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0px 25px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid black;
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      background-color: white;
      color: black;
    }
  }

  &.small {
    height: 34px;
    line-height: 34px;
    padding: 0px 12px;
  }

  &.centered {
    margin: 0 auto;
  }

  &.inverted {
    background-color: white;
    color: black;
    transition: all 0.3s ease;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &.reset-zoom {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    padding: 0px 6px;
    z-index: 1;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.1);
        background-color: black;
        color: white;
      }
    }
  }

  &.menu-option {
    border-left: none;
    border-right: none;
    border-top: none;
    width: 100%;
    text-align: left;
    position: relative;
    padding-left: 6px;
    justify-content: left;

    &::after {
      content: '';
      position: absolute;
      right: 3px;
      top: 20px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #000;
    }

    @media (hover: hover) {
      &:hover {
        transform: none;
        background-color: rgb(250,250,250);
      }
    }
  }

  &.no-after {
    &::after {
      content: none;
    }
  }

  &.disabled {
    background-color: rgb(200,200,200);
    border-color: rgb(200,200,200);
    color: rgb(80,80,80);

    &:hover {
      cursor: default;
    }
  }
}

@media (max-width: 785px), (max-height: 550px) {
  .button-container {
    height: 34px;
    line-height: 34px;
    padding: 0px 12px;
  }
}

@media (max-width: 590px) {
  .button-container {
    font-size: 10px;
    height: 26px;
    line-height: 26px;
    padding: 0px 8px;
  }
}
