::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-track-piece {
  background: #f0efef;
  border-left: 1px solid rgb(228, 227, 227);
}
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
  background: rgb(168, 168, 168);
  border-radius: 0px;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Courier New', monospace;
}

p {
  font-family: 'Raleway', 'Helvetica Neue', sans-serif;
}

.scroll-shadows {
  overflow-y: auto;

  background:
    /* Shadow Cover TOP */
    linear-gradient(
      rgb(252,252,252) 20%,
      rgb(252,252,252)
    ) center top,
    
    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(252, 252, 252, 0), 
      rgb(252,252,252) 80%
    ) center bottom,
    
    /* Shadow TOP */
    linear-gradient(
      rgba(100, 100, 100, 0.5) 20%,
      rgba(252, 252, 252, 0)
    ) center top,
    
    /* Shadow BOTTOM */
    linear-gradient(
      rgba(252, 252, 252, 0) 20%,
      rgba(100, 100, 100, 0.5)
    ) center bottom;
  
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

@media (max-width: 300px) {
  body {
    overflow-x: auto;
  }
}

@media (max-height: 468px) {
  body {
    overflow-y: auto;
  }
}
