.map-container {
  flex: 1;
  border: 2px solid black;
  box-sizing: border-box;
  position: relative;

  .map-hover-info {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: white;
    border: 1px solid rgb(90,90,90);
    border-radius: 4px;
    min-width: 170px;
    box-sizing: border-box;
    text-align: center;
    
    .map-hover-info-name {
      background: black;
      color: #fff;
      margin: 0;
      padding: 10px 8px;
      font-weight: 700;
      text-align: center;
    }

    .map-hover-info-value {
      margin: 0;
      padding: 10px 5px;
      font-weight: 700;
      text-align: center;
    }
  }
}

@media (max-width: 800px) {
  .map-container {
    min-height: 468px;

    .mapboxgl-map {
      height: 468px !important;
    }
  }
}