.App {
  background-color: rgb(252, 252, 252);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  main, .main-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex:1;

    .text-block {
      width: 90%;
      max-width: 1000px;
      min-width: 320px;
      margin: 24px auto;

      p {
        margin: 8px 0px;
      }
    }
  }
  
  .map-content-container {
    position: relative;
    display: flex;
    min-height: 468px;
    height: calc(100vh - 400px);
    width: 90vw;
    max-width: 1200px;
    min-width: 320px;
    box-sizing: border-box;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .map-content-container {
    height: fit-content !important;
    flex-direction: column;
  }
}