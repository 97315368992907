.data-date-type-container {
  text-align: center;
  padding: 10px;

  .data-date-type-text {
    margin: 0;
    font-size: 0.9rem;
  }
}

@media (max-width: 800px) {
  .data-date-type-container {
    padding: 0;
    border-bottom: 1px solid rgb(180,180,180);
    margin-bottom: 12px;
  }
}