.options-panel-container {
  border:1px solid rgb(180,180,180);
  border-right: none;
  width: 255px;
  overflow-y: auto;
}

@media (max-width: 800px) {
  .options-panel-container {
    width: calc(100% - 2px);
    border-right: 1px solid rgb(180,180,180);
    
    .options-panel-selectors {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      
      & > div {
        border: 1px solid black !important;
        align-self: flex-start;
      }
    }
  }
}