.tooltip-thresholds-container {
  display: grid;
  grid-template-rows: 20px repeat(3, 28px 16px);
  text-align: center;
  font-family: 'Raleway', 'Roboto', 'Helvetica Neue', sans-serif;
  margin-top: 5px;
  column-gap: 2px;

  .t-text {
    font-size: 12px;
    line-height: 12px;
    white-space: break-spaces;
  }

  .full-row {
    font-size: 12px;
    line-height: 16px;
    grid-column: 1 / -1;
    color: rgb(100, 100, 100);
  }

  .t-number {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 16px;
  }

  .t-section {
    border-top: 1px dashed rgb(200, 200, 200);
    padding-top: 6px;
  }
}
