.tooltip-season-line {
  .tooltip-full-line {
    width: 60%;
    margin: 0 auto;
    height: 1px;
    background-color: black;
  }

  .tooltip-season-container {
    display: flex;
    justify-content: space-between;

    .tooltip-flex-line {
      flex-grow: 1;
      height: 3px;
      background-color: black;
      align-self: center;
    }

    .tooltip-season-number {
      font-family: 'Roboto', 'Helvetica Neue', sans-serif;
      font-weight: bold;
      width: 50px;
      font-size: 20px;
      padding: 0px 3px;
    }
  }
}
