.markdown-content {
  p,h1,h2,h3,h4,h5,h6 {
    font-family: inherit;
  }
  
  h2 {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 12px 0px 0px 0px;
  }

  h3 {
    font-weight: bold;
  }

  ul {
    margin-top: 6px;

    li {
      margin-bottom: 10px;
    }
  }

  iframe {
    margin-bottom: 20px;
  }

  table {
    border: 1px solid black;
    border-collapse: collapse;

    tr > td,
    tr > th {
      border: 1px solid black;
      border-collapse: collapse;
    }
  }

  .btn-as-anchor {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
  }

  .superscript-anchor {
    text-decoration: none;
    color: #069 !important;
    position: relative;
    top: -5px;
    font-size: 12px !important;
  }
}