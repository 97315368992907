$base-width: calc(100% - 400px);

header {
  background-color: rgb(0,0,0,0.1);
  color: darkblue;
  position: relative;
  padding: 0px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  box-sizing: border-box;

  .clear-cache-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgb(150,0,0);
    color: white;
    border: none;
    border-radius: 5px;
    width: 175px;
    padding: 6px;

    &:hover {
      cursor: pointer;
      background-color: rgb(120,0,0);
    }
  }

  h1 {
    color: white;
    position: relative;
    z-index: 1;
    max-width: 55%;
    text-align: right;
  }

  .angled {
    border-bottom: 100px solid red;
    border-left: 100px solid transparent;
    height: 0;
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .angle-1 {
    width: $base-width;
    border-bottom-color: lightblue;
  }

  .angle-2 {
    width: calc($base-width - 50px);
    border-bottom-color: darkblue;
  }
}

@media (max-width: 940px) {
  header {
    padding: 0px 12px;

    h1 {
      font-size: 24px;
    }

    .angle-1 {
      width: calc($base-width + 125px);
    }
  
    .angle-2 {
      width: calc($base-width + 75px);
    }
  }
}

@media (max-width: 680px) {
  header {
    flex-direction: column;
    justify-content: center;
    background-color: darkblue;

    h1 {
      max-width: 100%;
      margin: 6px;
      text-align: center;
    }

    .angled {
      display: none;
    }
  }
}