$remove-size: 24px;
$arrow-size: 19px;

.threshold-item-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0px;

  .threshold-item-value {
    width: 55px;

    .MuiInput-underline:after {
      border-bottom-color: rgb(150, 150, 150);
    }

    input {
      padding-bottom: 0px;
    }
  }

  .threshold-item-controls {
    display: flex;
    align-items: center;

    .remove-container {
      height: $remove-size;
      width: $remove-size;
      padding: 5px;
      border-radius: 50%;
      margin: 0px 4px;

      &:hover {
        background-color: rgb(220, 220, 220);
        cursor: pointer;
      }

      .remove {
        height: $remove-size;
        width: $remove-size;
        position: relative;
        top: 1px;
      }
    }

    .arrow-container {
      width: $arrow-size;
      height: $arrow-size;
      text-align: center;
      padding: 1px;
      border-radius: 50%;

      &:hover {
        background-color: rgb(220, 220, 220);
        cursor: pointer;
      }

      .arrow {
        position: relative;
        top: -1px;
      }
    }
  }
}
