$accent-color: rgb(165, 163, 163);

.threshold-range-selector-content-container {
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    
    .header-value,
    .header-empty,
    .header-controls-container {
      font-size: 12px;
      font-weight: bold;
      color: rgb(150, 150, 150);
    }

    .header-value {
      flex-grow: 1;
      text-align: center;
    }
  
    .header-empty {
      width: 30.5px;
    }
  
    .header-controls-container {
      display: flex;
      gap: 6px;
    }

    .divider {
      height: 1px;
      margin: 2px 0px;
      width: 100%;
      background-color: $accent-color;
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  .add-button-container {
    border-top: 1px dashed $accent-color;
    padding: 5px 0px;
    margin-top: 5px;
  }
}
